

export class ContactUs {
    id: number;
    date: Date;
    subject: string;
    content: string;
    email: string;
    telephone: string;
}






