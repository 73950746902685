<!--====== SLIDER PART START ======-->
<!-- https://ayroui.com/ -->
<!-- https://old.ayroui.com/ -->

<section id="home" class="slider_area">
    <div id="carouselThree" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselThree" data-slide-to="0" class="active"></li>
            <li data-target="#carouselThree" data-slide-to="1"></li>
            <li data-target="#carouselThree" data-slide-to="2"></li>
            <li data-target="#carouselThree" data-slide-to="3"></li>
            <li data-target="#carouselThree" data-slide-to="4"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="container">
                    <div class="row align-items-center flex-container">
                        <div class="col-lg-7">
                            <div class="slider-content">
                                <h1 class="title">{{ 'HOME.MAIN_TITLE1' | translate }} </h1>
                                <p class="text white">{{ 'HOME.MAIN_SUBTITLE1' | translate }}</p>
                                <ul class="slider-btn rounded-buttons">
                                    <li>
                                        <a class="main-btn rounded-one"
                                            routerLink="https://admin.propero.io/"
                                            target="_blank">Propero assistant</a>
                                        <br />
                                        <span class="small-text">{{ 'HOME.MAIN_SUBTITLE_ADDITIONAL_TEXT' | translate
                                            }}</span>
                                        <br />
                                    </li>
                                    <li>
                                        <a class="main-btn rounded-one" routerLink="/solution-personalised">{{
                                            'HOME.MAIN_PERSONALIZED_OFFER' | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="slider-image">
                                <img src="assets/images/slider/1.png" alt="Hero">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-item">
                <div class="container">
                    <div class="row flex-container">
                        <div class="col-lg-7">
                            <div class="slider-content">
                                <h1 class="title">{{ 'HOME.MAIN_TITLE2' | translate }}</h1>
                                <p class="text">{{ 'HOME.MAIN_SUBTITLE2' | translate }}</p>
                                <ul class="slider-btn rounded-buttons">
                                    <li><a class="main-btn rounded-one"
                                            href="https://admin.propero.io/" target="_blank">Propero assistant</a>
                                        <br />
                                        <span i18n="@@mainSubtitleAdditionalText" class="small-text">{{
                                            'HOME.MAIN_SUBTITLE_ADDITIONAL_TEXT' | translate }}
                                        </span>
                                        <br />
                                    </li>
                                    <li>
                                        <a i18n="@@mainPersonalizedOffer" class="slave-btn rounded-one"
                                            routerLink="/solution-personalised">{{
                                            'HOME.MAIN_PERSONALIZED_OFFER' | translate
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="slider-image">
                                <img src="assets/images/slider/2.png" alt="Hero">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-item">
                <div class="container">
                    <div class="row flex-container">
                        <div class="col-lg-7">
                            <div class="slider-content">
                                <h1 class="title">{{ 'HOME.MAIN_TITLE3' | translate }}</h1>
                                <p class="text">{{ 'HOME.MAIN_SUBTITLE3' | translate }}</p>
                                <ul class="slider-btn rounded-buttons">
                                    <li><a class="main-btn rounded-one" href="{{ assistantHref }}"
                                            target="_blank">Propero assistant</a>
                                        <br />
                                        <span class="small-text">{{
                                            'HOME.MAIN_SUBTITLE_ADDITIONAL_TEXT' | translate }}</span>
                                        <br />
                                    </li>
                                    <li>
                                        <a class="slave-btn rounded-one" routerLink="/solution-personalised">{{
                                            'HOME.MAIN_PERSONALIZED_OFFER' | translate
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="slider-image">
                                <img src="assets/images/slider/3.png" alt="Hero">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-item">
                <div class="container">
                    <div class="row flex-container">
                        <div class="col-lg-7">
                            <div class="slider-content">
                                <h1 class="title">{{ 'HOME.MAIN_TITLE4' | translate }}</h1>
                                <p class="text">{{ 'HOME.MAIN_SUBTITLE4' | translate }}</p>
                                <ul class="slider-btn rounded-buttons">
                                    <li><a class="main-btn rounded-one" href="{{ assistantHref }}"
                                            target="_blank">Propero assistant</a>
                                        <br />
                                        <span class="small-text">{{
                                            'HOME.MAIN_SUBTITLE_ADDITIONAL_TEXT' | translate }}</span>
                                        <br />
                                    </li>
                                    <li>
                                        <a class="slave-btn rounded-one" routerLink="/solution-personalised">{{
                                            'HOME.MAIN_PERSONALIZED_OFFER' | translate
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="slider-image">
                                <img src="assets/images/slider/4.png" alt="Hero">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="carousel-item">
                <div class="container">
                    <div class="row flex-container">
                        <div class="col-lg-7">
                            <div class="slider-content">
                                <h1 class="title">{{ 'HOME.MAIN_TITLE5' | translate }}</h1>
                                <p class="text">{{ 'HOME.MAIN_SUBTITLE5' | translate }}</p>
                                <ul class="slider-btn rounded-buttons">
                                    <li><a class="main-btn rounded-one" href="{{ assistantHref }}"
                                            target="_blank">Propero assistant</a>
                                        <br />
                                        <span class="small-text">{{
                                            'HOME.MAIN_SUBTITLE_ADDITIONAL_TEXT' | translate }}</span>
                                        <br />
                                    </li>
                                    <li>
                                        <a class="slave-btn rounded-one" routerLink="/solution-personalised">{{
                                            'HOME.MAIN_PERSONALIZED_OFFER' | translate
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="slider-image">
                                <img src="assets/images/slider/5.png" alt="Hero">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <a class="carousel-control-prev" href="#carouselThree" role="button" data-slide="prev">
            <i class="lni lni-arrow-left"></i>
        </a>
        <a class="carousel-control-next" href="#carouselThree" role="button" data-slide="next">
            <i class="lni lni-arrow-right"></i>
        </a>
    </div>
</section>

<section id="services" class="features-area white-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-10">
                    <h3 class="title">{{ 'HOME.OFFERED_SOLUTIONS' | translate }}</h3>
                    <p class="text">{{ 'HOME.OFFERED_SOLUTIONS_DETAILS' | translate }} </p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-7 col-sm-9 mt-40">
                <div class="single-features">
                    <div class="features-title-icon d-flex justify-content-between">
                        <h4 class="features-title"><a routerLink="/solution1">
                                {{ 'HOME.FEATURE_AUTOMATE_OPERATIONS_TITLE' | translate }}
                            </a></h4>
                        <div class="features-icon">
                            <i class="lni lni-island"></i>
                            <img class="shape" src="assets/images/Shape-1.svg" alt="Shape">
                        </div>
                    </div>
                    <div class="features-content">
                        <p class="text tooltip-text-content">
                            {{ 'HOME.FEATURE_AUTOMATE_OPERATIONS_TEXT' | translate }}
                        </p>
                        <a class="features-btn" routerLink="/solution1">{{
                            'HOME.FEATURE_WANT_DETAILS' | translate }}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-7 col-sm-9 mt-40">
                <div class="single-features">
                    <div class="features-title-icon d-flex justify-content-between">
                        <h4 i18n="@@featureCollectPaymentsOnlineTitle" class="features-title"><a
                                routerLink="/solution2">
                                {{ 'HOME.FEATURE_COLLECT_PAYMENTS_ONLINE_TITLE' | translate }}
                            </a></h4>
                        <div class="features-icon">
                            <i class="lni lni-layout"></i>
                            <img class="shape" src="assets/images/Shape-1.svg" alt="Shape">
                        </div>
                    </div>
                    <div class="features-content">
                        <p i18n="@@featureCollectPaymentsOnlineText" class="text tooltip-text-content"> {{
                            'HOME.FEATURE_COLLECT_PAYMENTS_ONLINE_TEXT' | translate }}</p>
                        <a class="features-btn" routerLink="/solution2"> {{
                            'HOME.FEATURE_WANT_DETAILS' | translate }}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-7 col-sm-9 mt-40">
                <div class="single-features">
                    <div class="features-title-icon d-flex justify-content-between">
                        <h4 i18n="@@featureProfessionalReportTitle" class="features-title">
                            <a routerLink="/solution3"> {{
                                'HOME.FEATURE_PROFESSIONAL_REPORT_TITLE' | translate }}</a>
                        </h4>
                        <div class="features-icon">
                            <i class="lni lni-write"></i>
                            <img class="shape" src="assets/images/Shape-1.svg" alt="Shape">
                        </div>
                    </div>
                    <div class="features-content">
                        <p i18n="@@featureProfessionalReportText" class="text tooltip-text-content"> {{
                            'HOME.FEATURE_PROFESSIONAL_REPORT_TEXT' | translate }}</p>
                        <a i18n="@@featureWantDetails" class="features-btn" routerLink="/solution3"> {{
                            'HOME.FEATURE_WANT_DETAILS' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="pricing" class="pricing-area gray-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-25">
                    <h3 i18n="@@sectionPaymentTitle" class="title"> {{ 'HOME.SECTION_PAYMENT_TITLE' |
                        translate }}</h3>
                    <p i18n="@@sectionPaymentText" class="text"> {{ 'HOME.SECTION_PAYMENT_TEXT' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-7 col-sm-9 mt-30">
                <div class="pricing-style">
                    <div class="pricing-icon text-center">
                        <img src="assets/images/Icon-Basic.svg" alt="">
                    </div>
                    <div class="pricing-header text-center">
                        <h5 i18n="@@startPlanText" class="sub-title"> {{ 'HOME.START' |
                            translate }}</h5>
                        <p>
                            <span i18n="@@startPrice" class="month"> {{ 'HOME.START_PRICE' |
                                translate }}</span>
                        </p>
                    </div>
                    <div class="pricing-list">
                        <ul class="item-list">
                            <li><span i18n="@@startItem1Text" class="item">&#10003;</span> {{
                                'HOME.START_ITEM1_TEXT' | translate }}</li>
                            <li><span i18n="@@startItem2Text" class="item">&#10003;</span> {{
                                'HOME.START_ITEM2_TEXT' | translate }}</li>
                            <li><span i18n="@@startItem3Text" class="item">&#10003;</span> {{
                                'HOME.START_ITEM3_TEXT' | translate }}</li>
                            <li><span i18n="@@startItem4Text" class="item">&#10003;</span> {{
                                'HOME.START_ITEM4_TEXT' | translate }}</li>
                            <li><span i18n="@@startItem5Text" class="item">&#10003;</span> {{
                                'HOME.START_ITEM5_TEXT' | translate }}
                            </li>
                            <li><span i18n="@@startItem6Text" class="item">&#10003;</span> {{
                                'HOME.START_ITEM6_TEXT' | translate }}</li>
                        </ul>
                    </div>
                    <div class="mb-5">&nbsp;</div>
                    <div class="pricing-btn rounded-buttons text-center">
                        <a i18n="@@startText" class="main-btn rounded-one" routerLink="/pricing"> {{
                            'HOME.START' | translate }}</a>
                    </div>
                </div> <!-- pricing style one -->
            </div>

            <div class="col-lg-4 col-md-7 col-sm-9 mt-30">
                <div class="pricing-style">
                    <div class="pricing-icon text-center">
                        <img src="assets/images/Icon-Pro.svg" alt="">
                    </div>
                    <div class="pricing-header text-center">
                        <h5 i18n="@@smartPlanText" class="sub-title"> {{ 'HOME.SMART' |
                            translate }}</h5>
                        <p>
                            <span i18n="@@smartPrice" class="month"> {{ 'HOME.SMART_PRICE' |
                                translate }}</span>
                        </p>
                    </div>
                    <div class="pricing-list">
                        <ul class="item-list">
                            <li><span i18n="@@smartItem1Text" class="item">&#10003;</span> {{
                                'HOME.SMART_ITEM1_TEXT' | translate }}</li>
                            <li><span i18n="@@smartItem2Text" class="item">&#10003;</span> {{
                                'HOME.SMART_ITEM2_TEXT' | translate }}</li>
                            <li><span i18n="@@smartItem3Text" class="item">&#10003;</span> {{
                                'HOME.SMART_ITEM3_TEXT' | translate }}</li>
                            <li><span i18n="@@smartItem4Text" class="item">&#10003;</span> {{
                                'HOME.SMART_ITEM4_TEXT' | translate }}
                            </li>
                            <li><span i18n="@@smartItem5Text" class="item">&#10003;</span> {{
                                'HOME.SMART_ITEM5_TEXT' | translate }} </li>
                            <li><span i18n="@@smartItem6Text" class="item">&#10003;</span> {{
                                'HOME.SMART_ITEM6_TEXT' | translate }}</li>
                            <li><span i18n="@@smartItem7Text" class="item">&#10003;</span> {{
                                'HOME.SMART_ITEM7_TEXT' | translate }}</li>
                        </ul>
                    </div>
                    <div class="mb-5">&nbsp;</div>
                    <div class="pricing-btn rounded-buttons text-center">
                        <a i18n="@@startText" class="main-btn rounded-one" routerLink="/pricing"> {{
                            'HOME.SMART' | translate }}</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-7 col-sm-9 mt-30">
                <div class="pricing-style">
                    <div class="pricing-icon text-center">
                        <img src="assets/images/Icon-Enterprise.svg" alt="">
                    </div>
                    <div class="pricing-header text-center">
                        <h5 i18n="@@scalePlanText" class="sub-title"> {{ 'HOME.SCALE' |
                            translate }}</h5>
                        <p>
                            <span i18n="@@scalePrice" class="month"> {{ 'HOME.SCALE_PRICE' |
                                translate }}</span>
                        </p>
                    </div>
                    <div class="pricing-list">
                        <ul class="item-list">
                            <li><span i18n="@@scaleItem1Text" class="item">&#10003;</span> {{
                                'HOME.SCALE_ITEM1_TEXT' | translate }}</li>
                            <li><span i18n="@@scaleItem2Text" class="item">&#10003;</span> {{
                                'HOME.SCALE_ITEM2_TEXT' | translate }}</li>
                            <li><span i18n="@@scaleItem3Text" class="item">&#10003;</span> {{
                                'HOME.SCALE_ITEM3_TEXT' | translate }}
                            </li>
                            <li><span i18n="@@scaleItem4Text" class="item">&#10003;</span> {{
                                'HOME.SCALE_ITEM4_TEXT' | translate }}
                            </li>
                            <li><span i18n="@@scaleItem5Text" class="item">&#10003;</span> {{
                                'HOME.SCALE_ITEM5_TEXT' | translate }} </li>
                            <li><span i18n="@@scaleItem6Text" class="item">&#10003;</span> {{
                                'HOME.SCALE_ITEM6_TEXT' | translate }}</li>
                            <li><span i18n="@@scaleItem7Text" class="item">&#10003;</span> {{
                                'HOME.SCALE_ITEM7_TEXT' | translate }}</li>
                            <li><span i18n="@@scaleItem8Text" class="item">&#10003;</span> {{
                                'HOME.SCALE_ITEM8_TEXT' | translate }}</li>
                        </ul>
                    </div>
                    <div class="mb-5">&nbsp;</div>
                    <div class="pricing-btn rounded-buttons text-center">
                        <a i18n="@@startText" class="main-btn rounded-one" routerLink="/pricing"> {{ 'HOME.SCALE' |
                            translate
                            }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="resources" class="portfolio-area portfolio-four pb-100 white-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-25">
                    <h3 i18n="@@sectionResourcesTitle" class="title"> {{ 'HOME.SECTION_RESOURCES_TITLE' |
                        translate }}</h3>
                    <p i18n="@@sectionResourcesText" class="text"> {{ 'HOME.SECTION_RESOURCES_TEXT' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-card card-style-one mt-30">
                    <div class="card-image">
                        <img src="assets/images/card/card-1.jpg" alt="Image">
                    </div>
                    <div class="card-content">
                        <h4 i18n="@@featureHelpTitle" class="card-title"><a routerLink="#"> {{ 'HOME.FEATURE_HELP_TITLE'
                                |
                                translate }}</a></h4>
                        <p i18n="@@featureHelpText" class="text tooltip-text-content"> {{ 'HOME.FEATURE_HELP_TEXT' |
                            translate }}</p>
                        <div class="card-btn rounded-buttons">
                            <a i18n="@@hereText" class="main-btn rounded-three" routerLink="/help"> {{ 'HOME.HERE' |
                                translate
                                }}</a>
                        </div>
                    </div>
                </div>
                <!-- <div class="single-card card-style-three mt-30">
                    <div class="card-image">
                        <img src="assets/images/card/card-3.jpg" alt="Image">
                    </div>
                    <div class="card-content">
                        <h4 class="card-title"><a href="#">Item title is here</a></h4>
                        <p class="text">Short description for the ones who look for something new</p>
                    </div>
                </div>  <div class="single-card card-style-five mt-30">
                    <div class="card-image">
                        <img src="assets/images/card/card-4.jpg" alt="Image">
                    </div>
                    <div class="card-content">
                        <h4 class="card-title"><a href="#">Item title is here</a></h4>
                        <p class="text">Short description for the ones who look for something new</p>
                        <a class="card-icon" href="#"><i class="lni lni-cloud-download"></i></a>
                    </div>
                </div> -->
            </div>
            <div class="col-lg-8">
                <!-- <div class="single-card card-style-two mt-30">
                    <div class="row no-gutters align-items-center">
                        <div class="col-sm-6">
                            <div class="card-image">
                                <img src="assets/images/card/card-2.jpg" alt="Image">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card-content">
                                <h4 class="card-title"><a href="#">Item title is here</a></h4>
                                <p class="text">Short description for the ones who look for something new</p>
                                <div class="card-btn rounded-buttons">
                                    <a class="main-btn rounded-three" href="#">GET STARTED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="single-card card-style-four mt-30">
                    <div class="row no-gutters align-items-center">
                        <div class="col-sm-6 order-md-last">
                            <div class="card-image">
                                <img src="assets/images/card/card-4.jpg" alt="Image">
                            </div>
                        </div>
                        <div class="col-sm-6 order-md-first">
                            <div class="card-content">
                                <h4 class="card-title"><a href="#">Item title is here</a></h4>
                                <p class="text">Short description for the ones who look for something new</p>
                                <div class="card-btn rounded-buttons">
                                    <a class="main-btn rounded-one" href="#">GET STARTED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  -->
                <div class="single-card card-style-six mt-30">
                    <div class="row no-gutters align-items-center">
                        <div class="col-sm-6">
                            <div class="card-image">
                                <img src="assets/images/card/card-5.jpg" alt="Image">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card-content">
                                <h4 i18n="@@featureDocumentsTitle" class="card-title"><a routerLink="#"> {{
                                        'HOME.FEATURE_DOCUMENTS_TITLE' | translate }}</a></h4>
                                <p i18n="@@featureDocumentsText" class="text tooltip-text-content"> {{
                                    'HOME.FEATURE_DOCUMENTS_TEXT' | translate }}
                                </p>
                                <div class="card-btn rounded-buttons">
                                    <a i18n="@@hereText" class="main-btn rounded-three" routerLink="/documents"> {{
                                        'HOME.HERE' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="single-card card-style-saven mt-30">
                    <div class="row no-gutters align-items-center">
                        <div class="col-sm-6 order-md-last">
                            <div class="card-image">
                                <img src="assets/images/card/card-3.jpg" alt="Image">
                            </div>
                        </div>
                        <div class="col-sm-6 order-md-first">
                            <div class="card-content">
                                <h4 class="card-title"><a routerLink="#"> {{ 'HOME.FEATURE_CALCULATORS_TITLE' |
                                        translate
                                        }}</a></h4>
                                <p class="text tooltip-text-content"> {{ 'HOME.FEATURE_CALCULATORS_TEXT' | translate }}
                                </p>
                                <div class="card-btn rounded-buttons">
                                    <a i18n="@@hereText" class="main-btn rounded-one" routerLink="/calculators"> {{
                                        'HOME.HERE' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="blog" class="blog-area blog-five pb-100 grey-area">
    <div class="container">

        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-25">
                    <h3 i18n="@@sectionResourcesTitle" class="title">{{ 'HOME.SECTION_BLOG_TITLE' | translate }}
                    </h3>
                    <p i18n="@@sectionResourcesText" class="text">{{ 'HOME.SECTION_BLOG_TEXT' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-8 col-sm-10" *ngFor="let blogItem of latestBlogItems">
                <div class="single-blog blog-style-one">
                    <div class="blog-image">
                        <button href="/blog-post?id={{blogItem.id}}" target="_blank">
                            <img [src]="blogItem.imageURL || '../assets/images/blog/1.jpg'" alt="Blog" />
                        </button>
                        <a href="/blog-post?id={{blogItem.id}}" class="category" target="_blank">{{ blogItem.BlogCategory.name ||
                            'Fără categorie'
                            }}</a>
                    </div>
                    <div class="blog-content">
                        <h5 class="blog-title">
                            <a href="/blog-post?id={{blogItem.id}}" target="_blank">
                                <h3>{{ blogItem.title }}</h3>
                            </a>
                        </h5>
                        <span><i class="lni lni-calendar"></i> {{ blogItem.publishedDate | date: 'MMM d, y'
                            }}</span>
                        <span><i class="lni lni-comments-alt"></i> {{ blogItem.commentsCount || 0 }} Comment{{
                            blogItem.commentsCount === 1 ? '' : 's' }}</span>
                        <p class="text">{{ blogItem.summary || 'Blog-ul nu are sumar.' }}</p>
                        <a class="more" href="/blog-post?id={{blogItem.id}}" target="_blank">Citește</a>
                    </div>
                </div>
                <!-- single blog -->
            </div>
        </div>
        <!-- row -->


        <!-- 
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-8 col-sm-10">
                <div class="single-blog blog-style-one">
                    <div class="blog-image">
                        <a href="javascript:void(0)"><img src="../assets/images/blog/1.jpg" alt="Blog" /></a>
                        <a href="javascript:void(0)" class="category">Technology</a>
                    </div>
                    <div class="blog-content">
                        <h5 class="blog-title">
                            <a href="javascript:void(0)">
                                Budget-Friendly Design Ideas to Transform Your Office
                            </a>
                        </h5>
                        <span><i class="lni lni-calendar"></i> Mar 23, 2022</span>
                        <span><i class="lni lni-comments-alt"></i> 24 Comment</span>
                        <p class="text">
                            Create a workspace your team will love with these money-saving
                            interior Ui/Ux design tips Learn how People.
                        </p>
                        <a class="more" href="javascript:void(0)">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-8 col-sm-10">
                <div class="single-blog blog-style-one">
                    <div class="blog-image">
                        <a href="javascript:void(0)"><img src="../assets/images/blog/2.jpg" alt="Blog" /></a>
                        <a href="javascript:void(0)" class="category">Lifestyle</a>
                    </div>
                    <div class="blog-content">
                        <h5 class="blog-title">
                            <a href="javascript:void(0)">
                                Budget-Friendly Design Ideas to Transform Your Office
                            </a>
                        </h5>
                        <span><i class="lni lni-calendar"></i> Mar 23, 2022</span>
                        <span><i class="lni lni-comments-alt"></i> 24 Comment</span>
                        <p class="text">
                            Create a workspace your team will love with these money-saving
                            interior Ui/Ux design tips Learn how People.
                        </p>
                        <a class="more" href="javascript:void(0)">READ MORE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-8 col-sm-10">
                <div class="single-blog blog-style-one">
                    <div class="blog-image">
                        <a href="javascript:void(0)"><img src="../assets/images/blog/3.jpg" alt="Blog" /></a>
                        <a href="javascript:void(0)" class="category">Science</a>
                    </div>
                    <div class="blog-content">
                        <h5 class="blog-title">
                            <a href="javascript:void(0)">
                                Budget-Friendly Design Ideas to Transform Your Office
                            </a>
                        </h5>
                        <span><i class="lni lni-calendar"></i> Mar 23, 2022</span>
                        <span><i class="lni lni-comments-alt"></i> 24 Comment</span>
                        <p class="text">
                            Create a workspace your team will love with these money-saving
                            interior Ui/Ux design tips Learn how People.
                        </p>
                        <a class="more" href="javascript:void(0)">READ MORE</a>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- row -->
    </div>
</section>

<section id="about" class="about-area white-area">
    <div class="container">
        <div class="row">
            <div class="faq-content mt-45">
                <div class="about-title">
                    <h6 i18n="@@sectionFaqTitle" class="sub-title"> {{ 'HOME.SECTION_FAQ_TITLE' |
                        translate }}</h6>
                    <h4 i18n="@@sectionFaqSubtitle" class="title"> {{ 'HOME.SECTION_FAQ_TEXT' |
                        translate }} </h4>
                </div>
                <div class="about-accordion">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a i18n="@@featureQuestion1Title" routerLink="#" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> {{
                                    'HOME.FEATURE_QUESTION1_TITLE' | translate }}</a>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@featureQuestion1Text" class="text"> {{ 'HOME.FEATURE_QUESTION1_TEXT' |
                                        translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a i18n="@@featureQuestion2Title" routerLink="#" class="collapsed"
                                    data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo"> {{
                                    'HOME.FEATURE_QUESTION2_TITLE' | translate }}</a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@featureQuestion2Text" class="text"> {{
                                        'HOME.FEATURE_QUESTION2_TEXT' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a i18n="@@featureQuestion3Title" routerLink="#" class="collapsed"
                                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree"> {{
                                    'HOME.FEATURE_QUESTION3_TITLE' | translate }}</a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@featureQuestion3Text" class="text"> {{
                                        'HOME.FEATURE_QUESTION3_TEXT' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFore">
                                <a i18n="@@featureQuestion4Title" routerLink="#" class="collapsed"
                                    data-toggle="collapse" data-target="#collapseFore" aria-expanded="false"
                                    aria-controls="collapseFore"> {{
                                    'HOME.FEATURE_QUESTION4_TITLE' | translate }}</a>
                            </div>
                            <div id="collapseFore" class="collapse" aria-labelledby="headingFore"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@featureQuestion4Text" class="text"> {{
                                        'HOME.FEATURE_QUESTION4_TEXT' | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a i18n="@@featureQuestion5Title" routerLink="#" class="collapsed"
                                    data-toggle="collapse" data-target="#collapseFive" aria-expanded="false"
                                    aria-controls="collapseFive"> {{
                                    'HOME.FEATURE_QUESTION5_TITLE' | translate }}</a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@featureQuestion5Text" class="text"> {{
                                        'HOME.FEATURE_QUESTION5_TEXT' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="contact" class="contact-area grey-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-30">
                    <h3 i18n="@@sectionContactUsTitle" class="title"> {{ 'HOME.SECTION_CONTACTUS_TITLE' |
                        translate }}</h3>
                    <p i18n="@@sectionContactUsText" class="text"> {{ 'HOME.SECTION_CONTACTUS_TEXT' |
                        translate }}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="contact-map mt-30">
                    <iframe id="gmap_canvas"
                        src="https://maps.google.com/maps?q=brasov&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"
                        scrolling="no" marginheight="0" marginwidth="0"></iframe>
                </div>
            </div>
        </div>
        <div class="contact-info pt-30">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-contact-info contact-color-1 mt-30 d-flex ">
                        <div class="contact-info-icon">
                            <i class="lni lni-map-marker"></i>
                        </div>
                        <div class="contact-info-content media-body">
                            <p class="text">Str. Calea Bucurest nr. 20 Sc B Ap 4. Brașov, Romania.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-contact-info contact-color-2 mt-30 d-flex">
                        <div class="contact-info-icon">
                            <i class="lni lni-envelope"></i>
                        </div>
                        <div class="contact-info-content media-body">
                            <p class="text">contact@propero.com</p>
                            <!-- <p class="text">support@myimmo.com</p> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-contact-info contact-color-3 mt-30 d-flex ">
                        <div class="contact-info-icon">
                            <i class="lni lni-phone"></i>
                        </div>
                        <div class="contact-info-content media-body">
                            <p class="text">(+4) 0743 366 266</p>
                            <!-- <p class="text">+4 743 366 266</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="contact-wrapper form-style-two pt-115">
                    <h4 class="contact-title pb-10">
                        <i i18n="@@textMessage" class="lni lni-envelope"></i> {{
                        'HOME.TEXT_MESSAGE' | translate }}
                    </h4>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-input mt-25">
                                <label i18n="@@formContactUsName"> {{ 'HOME.NAME' |
                                    translate }}</label>
                                <div class="input-items default">
                                    <input matInput required name="name" type="text" placeholder="Nume"
                                        [(ngModel)]="contactItem.subject" ngDefaultControl>
                                    <i class="lni lni-user"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-input mt-25">
                                <label i18n="@@formContactUsEmail"> {{ 'HOME.EMAIL' |
                                    translate }}</label>
                                <div class="input-items default">
                                    <input matInput type="email" name="email" placeholder="Email"
                                        [(ngModel)]="contactItem.email" ngDefaultControl>
                                    <i class="lni lni-envelope"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-input mt-25">
                                <label i18n="@@formContactUsMessage"> {{ 'HOME.MESSAGE' |
                                    translate }}</label>
                                <div class="input-items default">
                                    <textarea matInput required name="massage" placeholder="Mesaj"
                                        [(ngModel)]="contactItem.content" ngDefaultControl></textarea>
                                    <i class="lni lni-pencil-alt"></i>
                                </div>
                            </div>
                        </div>
                        <p class="form-message"></p>
                        <div class="col-md-12">
                            <div class="form-input light-rounded-buttons mt-30">
                                <button class="main-btn light-rounded-two" (click)="send()"><span
                                        i18n="@@formContactUsSend"> {{ 'HOME.SEND' | translate }}</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>