<section id="home" class="slider_area">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="slider-content">&nbsp;</div>
        </div>
    </div>
</section>

<section class="white-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10">
                <div class="section-title text-center pb-10">
                    <h3 class="title">{{'HELP_CENTER.SOON' | translate }}</h3>
                    <p class="text">{{'HELP_CENTER.HELP' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>