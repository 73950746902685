<!--====== SLIDER PART START ======-->
<!-- https://ayroui.com/ -->

<section id="home" class="slider_area">
    <div id="carouselThree" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselThree" data-slide-to="0" class="active"></li>


        </ol>
        <div class="carousel-inner">

            <div class="carousel-item active">
                <div class="container">
                    <div class="row flex-container">
                        <div class="col-lg-7">
                            <div class="slider-content">
                                <h1 class="title">Documente și rapoarte</h1>
                                <p class="text">Șabloane profesionale pentru fiecare etapă: de la contracte personalizabile și fișe detaliate de inventar, până la procese verbale de predare-primire.</p>
                                <ul class="slider-btn rounded-buttons">
                                    <li><a class="main-btn rounded-one"
                                            href="https://admin.propero.io/" target="_blank">Propero Assistant</a>
                                        <br />
                                        <span i18n="@@mainSubtitleAdditionalText" class="small-text">{{
                                            'HOME.MAIN_SUBTITLE_ADDITIONAL_TEXT' | translate }}
                                        </span>
                                        <br />
                                    </li>
                                    <li>
                                        <a i18n="@@mainPersonalizedOffer" class="slave-btn rounded-one"
                                            routerLink="/solution-personalised">{{
                                            'HOME.MAIN_PERSONALIZED_OFFER' | translate
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="slider-image">
                                <img src="assets/images/slider/2.png" alt="Hero">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <a class="carousel-control-prev" href="#carouselThree" role="button" data-slide="prev">
            <i class="lni lni-arrow-left"></i>
        </a>
        <a class="carousel-control-next" href="#carouselThree" role="button" data-slide="next">
            <i class="lni lni-arrow-right"></i>
        </a>
    </div>
</section>

<section class="features-area white-area">
    <div class="container">

        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-10">
                    <h3 class="title">{{'DOCUMENTS.TITLE' | translate }}</h3>
                    <p class="text">{{'DOCUMENTS.TEXT' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">

            <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-features mt-40">

                    <div class="features-title-icon d-flex justify-content-between">
                        <h4 class="features-title">
                            <a (click)="generateReportRentingContract()" target="_blank">
                                <span class="tooltip-text-title">{{ 'DOCUMENTS.FEATURE1_TITLE' | translate }}</span>
                            </a>
                        </h4>
                        <div class="features-icon">
                            <i class="lni lni-display-alt"></i>
                            <img class="shape" src="assets/images/Shape-1.svg" alt="Shape">
                        </div>
                    </div>

                    <div class="features-content">
                        <p class="text">
                            <a class="tooltip-text-content" (click)="generateReportRentingContract()" target="_blank">
                                <span>{{ 'DOCUMENTS.FEATURE1_TEXT' | translate }}</span>
                            </a>
                        </p>
                        <a class="features-btn" (click)="generateReportRentingContract()" target="_blank">
                            {{ 'DOCUMENTS.DOWNLOAD' | translate }}
                        </a>
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-features mt-40">

                    <div class="features-title-icon d-flex justify-content-between">
                        <h4 class="features-title">
                            <a (click)="generateReportInventory()" target="_blank">
                                <span class="tooltip-text-title">{{ 'DOCUMENTS.FEATURE2_TITLE' | translate }}</span>
                            </a>
                        </h4>
                        <div class="features-icon">
                            <i class="lni lni-display-alt"></i>
                            <img class="shape" src="assets/images/Shape-1.svg" alt="Shape">
                        </div>
                    </div>

                    <div class="features-content">
                        <p class="text">
                            <a class="tooltip-text-content" (click)="generateReportInventory()" target="_blank">
                                <span>{{ 'DOCUMENTS.FEATURE2_TEXT' | translate }}</span>
                            </a>
                        </p>
                        <a class="features-btn" (click)="generateReportInventory()" target="_blank">
                            {{ 'DOCUMENTS.DOWNLOAD' | translate }}
                        </a>
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-features mt-40">

                    <div class="features-title-icon d-flex justify-content-between">
                        <h4 class="features-title">
                            <a (click)="generateReportSubRentingContract()" target="_blank">
                                <span class="tooltip-text-title">{{ 'DOCUMENTS.FEATURE3_TITLE' | translate }}</span>
                            </a>
                        </h4>
                        <div class="features-icon">
                            <i class="lni lni-display-alt"></i>
                            <img class="shape" src="assets/images/Shape-1.svg" alt="Shape">
                        </div>
                    </div>

                    <div class="features-content">
                        <p class="text">
                            <a class="tooltip-text-content" (click)="generateReportSubRentingContract()"
                                target="_blank">
                                <span>{{ 'DOCUMENTS.FEATURE3_TEXT' | translate }}</span>
                            </a>
                        </p>
                        <a class="features-btn" (click)="generateReportSubRentingContract()" target="_blank">
                            {{ 'DOCUMENTS.DOWNLOAD' | translate }}
                        </a>
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-features mt-40">

                    <div class="features-title-icon d-flex justify-content-between">
                        <h4 class="features-title">
                            <a (click)="generateRequestEndContractBeforeEndOfTerm()" target="_blank">
                                <span class="tooltip-text-title">{{ 'DOCUMENTS.FEATURE4_TITLE' | translate }}</span>
                            </a>
                        </h4>
                        <div class="features-icon">
                            <i class="lni lni-display-alt"></i>
                            <img class="shape" src="assets/images/Shape-1.svg" alt="Shape">
                        </div>
                    </div>

                    <div class="features-content">
                        <p class="text">
                            <a class="tooltip-text-content" (click)="generateRequestEndContractBeforeEndOfTerm()"
                                target="_blank">
                                <span>{{ 'DOCUMENTS.FEATURE4_TEXT' | translate }}</span>
                            </a>
                        </p>
                        <a class="features-btn" (click)="generateRequestEndContractBeforeEndOfTerm()" target="_blank">
                            {{ 'DOCUMENTS.DOWNLOAD' | translate }}
                        </a>
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-features mt-40">

                    <div class="features-title-icon d-flex justify-content-between">
                        <h4 class="features-title">
                            <a (click)="generateDataBeneficiarRealContract()" target="_blank">
                                <span class="tooltip-text-title">{{ 'DOCUMENTS.FEATURE5_TITLE' | translate }}</span>
                            </a>
                        </h4>
                        <div class="features-icon">
                            <i class="lni lni-display-alt"></i>
                            <img class="shape" src="assets/images/Shape-1.svg" alt="Shape">
                        </div>
                    </div>

                    <div class="features-content">
                        <p class="text">
                            <a class="tooltip-text-content" (click)="generateDataBeneficiarRealContract()"
                                target="_blank">
                                <span>{{ 'DOCUMENTS.FEATURE5_TEXT' | translate }}</span>
                            </a>
                        </p>
                        <a class="features-btn" (click)="generateDataBeneficiarRealContract()" target="_blank">
                            {{ 'DOCUMENTS.DOWNLOAD' | translate }}
                        </a>
                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-7 col-sm-9">
                <div class="single-features mt-40">

                    <div class="features-title-icon d-flex justify-content-between">
                        <h4 class="features-title">
                            <a (click)="generateAcordGDPR()" target="_blank">
                                <span class="tooltip-text-title">{{ 'DOCUMENTS.FEATURE6_TITLE' | translate }}</span>
                            </a>
                        </h4>
                        <div class="features-icon">
                            <i class="lni lni-display-alt"></i>
                            <img class="shape" src="assets/images/Shape-1.svg" alt="Shape">
                        </div>
                    </div>

                    <div class="features-content">
                        <p class="text">
                            <a class="tooltip-text-content" (click)="generateAcordGDPR()" target="_blank">
                                <span>{{ 'DOCUMENTS.FEATURE6_TEXT' | translate }}</span>
                            </a>
                        </p>
                        <a class="features-btn" (click)="generateAcordGDPR()" target="_blank">
                            {{ 'DOCUMENTS.DOWNLOAD' | translate }}
                        </a>
                    </div>

                </div>
            </div>

        </div>
    </div>
</section>