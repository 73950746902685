<section id="home" class="slider_area">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="slider-content">&nbsp;</div>
        </div>
    </div>
</section>

<section id="pricing" class="compare-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-25">
                    <h3 i18n="@@sectionPricingTitle" class="title">{{'PRICING.TITLE' | translate }}</h3>
                    <p i18n="@@sectionPricingText" class="text">{{'PRICING.TEXT' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="row compare-area-table justify-content-center mb-30">
            <table style="width: 100%;">
                <tr>
                    <th style="width: 25%;">
                        <div i18n="@@featureFunctionalityTitle" class="compare-table-header">{{'PRICING.FUNCTIONALITIES'
                            | translate }}</div>
                    </th>
                    <th style="width: 25%;">
                        <div i18n="@@featureStartPlanTitle" class="compare-table-header"
                            style="color: #0067f4; text-align: center;">{{'PRICING.START_PLAN' | translate }}</div>
                    </th>
                    <th style="width: 25%;">
                        <div i18n="@@featureSmartPlanTitle" class="compare-table-header"
                            style="color: #0067f4;; text-align: center;">{{'PRICING.SMART_PLAN' | translate }}</div>
                    </th>
                    <th style="width: 25%;">
                        <div i18n="@@featureScalePlanTitle" class="compare-table-header"
                            style="color: #0067f4;; text-align: center;">{{'PRICING.SCALE_PLAN' | translate }}</div>
                    </th>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item1Title" class="compare-table-row-name">{{'PRICING.ITEM1_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item1StartText" class="compare-table-row-item">{{'PRICING.ITEM1_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item1SmartText" class="compare-table-row-item">{{'PRICING.ITEM1_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item1ScaleText" class="compare-table-row-item">{{'PRICING.ITEM1_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item2Title" class="compare-table-row-name">{{'PRICING.ITEM2_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item2StartText" class="compare-table-row-item">{{'PRICING.ITEM2_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item2SmartText" class="compare-table-row-item">{{'PRICING.ITEM2_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item2ScaleText" class="compare-table-row-item">{{'PRICING.ITEM2_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item3Title" class="compare-table-row-name">{{'PRICING.ITEM3_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item3StartText" class="compare-table-row-item">{{'PRICING.ITEM3_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item3SmartText" class="compare-table-row-item">{{'PRICING.ITEM3_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item3ScaleText" class="compare-table-row-item">{{'PRICING.ITEM3_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item4Title" class="compare-table-row-name">{{'PRICING.ITEM4_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item4StartText" class="compare-table-row-item bold">{{'PRICING.ITEM4_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item4SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM4_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item4ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM4_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item5Title" class="compare-table-row-name">{{'PRICING.ITEM5_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM5_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM5_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM5_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item6Title" class="compare-table-row-name">{{'PRICING.ITEM6_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM6_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM6_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM6_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item7Title" class="compare-table-row-name">{{'PRICING.ITEM7_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM7_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM7_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM7_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item8Title" class="compare-table-row-name">{{'PRICING.ITEM8_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM8_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM8_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM8_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item9Title" class="compare-table-row-name">{{'PRICING.ITEM9_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM9_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM9_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM9_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item10Title" class="compare-table-row-name">{{'PRICING.ITEM10_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM10_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM10_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM10_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item11Title" class="compare-table-row-name">{{'PRICING.ITEM11_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM11_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM11_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM11_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item12Title" class="compare-table-row-name">{{'PRICING.ITEM12_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM12_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM12_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM12_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item13Title" class="compare-table-row-name">{{'PRICING.ITEM13_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM13_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM13_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM13_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item14Title" class="compare-table-row-name">{{'PRICING.ITEM14_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM14_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM14_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM14_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item15Title" class="compare-table-row-name">{{'PRICING.ITEM15_TITLE' | translate }}
                        </div>
                    </td>
                    <td>
                        <div i18n="@@item5StartText" class="compare-table-row-item bold">{{'PRICING.ITEM15_START_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5SmartText" class="compare-table-row-item bold">{{'PRICING.ITEM15_SMART_TEXT' |
                            translate }}</div>
                    </td>
                    <td>
                        <div i18n="@@item5ScaleText" class="compare-table-row-item bold">{{'PRICING.ITEM15_SCALE_TEXT' |
                            translate }}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div i18n="@@item16Title" class="compare-table-row-name">&nbsp;</div>
                    </td>
                    <td>
                        <div i18n="@@item16StartText" class="compare-table-row-item bold">&nbsp;</div>
                    </td>
                    <td>
                        <div i18n="@@item16SmartText" class="compare-table-row-item bold">&nbsp;</div>
                    </td>
                    <td>
                        <div i18n="@@item16ScaleText" class="compare-table-row-item bold">&nbsp;</div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</section>

<section class="about-area">
    <div class="container">
        <div class="row">
            <div class="faq-content">
                <div class="about-title">
                    <h6 i18n="@@sectionAdditionalDetailsTitle" class="sub-title">{{'PRICING.ADDITIONAL_DETAILS' |
                        translate }}</h6>
                    <h4 i18n="@@sectionAdditionalDetailsText" class="title">{{'PRICING.FAQ' | translate }}
                    </h4>
                </div>
                <div class="about-accordion">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a i18n="@@question1Text" href="#" class="collapsed" data-toggle="collapse"
                                    data-target="#collapseTwo" aria-expanded="false"
                                    aria-controls="collapseTwo">{{'PRICING.QUESTION1' | translate }}
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@question1Answer" class="text">{{'PRICING.Q_ANSWER1' | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a i18n="@@question2Text" href="#" class="collapsed" data-toggle="collapse"
                                    data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">{{'PRICING.QUESTION2' | translate }}
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@question2Answer" class="text">{{'PRICING.Q_ANSWER2' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>