<!--====== SLIDER PART START ======-->
<!-- https://ayroui.com/ -->

<section id="home" class="slider_area">
    <div id="carouselThree" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselThree" data-slide-to="0" class="active"></li>


        </ol>
        <div class="carousel-inner">

            <div class="carousel-item active">
                <div class="container">
                    <div class="row flex-container">
                        <div class="col-lg-7">
                            <div class="slider-content">
                                <h1 class="title">{{ 'SOLUTION2.TITLE' | translate }}</h1>
                                <p class="text">{{ 'SOLUTION2.TEXT' | translate }}</p>
                                <ul class="slider-btn rounded-buttons">
                                    <li><a class="main-btn rounded-one"
                                            href="https://admin.propero.io/" target="_blank">Propero
                                            Assistant</a>
                                        <br />
                                        <span i18n="@@mainSubtitleAdditionalText" class="small-text">{{
                                            'HOME.MAIN_SUBTITLE_ADDITIONAL_TEXT' | translate }}
                                        </span>
                                        <br />
                                    </li>
                                    <li>
                                        <a i18n="@@mainPersonalizedOffer" class="slave-btn rounded-one"
                                            routerLink="/solution-personalised">{{
                                            'HOME.MAIN_PERSONALIZED_OFFER' | translate
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="slider-image">
                                <img src="assets/images/slider/2.png" alt="Hero">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <a class="carousel-control-prev" href="#carouselThree" role="button" data-slide="prev">
            <i class="lni lni-arrow-left"></i>
        </a>
        <a class="carousel-control-next" href="#carouselThree" role="button" data-slide="next">
            <i class="lni lni-arrow-right"></i>
        </a>
    </div>
</section>

<section class="features-area white-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-10">
                    <h3 class="title">{{'SOLUTION2.SECTION_TITLE' | translate }}</h3>
                    <p class="text">{{'SOLUTION2.SECTION_TEXT' | translate }}</p>
                </div>
            </div>
        </div>

        <div class="row compare-area-table justify-content-center">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div>
                                <img src="assets/images/marketing/Image-08-0.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@feature1Title" class="features-title center ">
                                {{'SOLUTION2.FEATURE1_TITLE' | translate }}
                            </h4>
                        </div>
                        <div class="features-content">
                            <p class="tooltip-text-content">
                                <span> {{'SOLUTION2.FEATURE1_TEXT' | translate
                                    }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div>
                                <img src="assets/images/marketing/Image-08-1.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@feature2Title" class="features-title center">
                                {{'SOLUTION2.FEATURE2_TITLE' | translate }}
                            </h4>
                        </div>
                        <div class="features-content">
                            <p i18n="@@feature2Text" class="tooltip-text-content">
                                {{'SOLUTION2.FEATURE2_TEXT' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div>
                                <img src="assets/images/marketing/Image-08-2.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@feature3Title" class="features-title center">
                                {{'SOLUTION2.FEATURE3_TITLE' | translate }}
                            </h4>
                        </div>
                        <div class="features-content">
                            <p i18n="@@feature3Text" class="tooltip-text-content">
                                {{'SOLUTION2.FEATURE3_TEXT' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-footer center mt-40">
            <ul class="slider-btn rounded-buttons">
                <li><a i18n="@@featureButtonText" class="main-btn rounded-one"
                        href="https://admin.propero.io/register" target="_blank">
                        {{'SOLUTION1.CREATE_ACCOUNT' | translate }}</a></li>
            </ul>
        </div>
    </div>
</section>


<section class="gray-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="left">
                    <h1 i18n="@@featureAutomaticAccountancyTitle" class="features-title left">
                        {{'SOLUTION2.EXTRA_DETAILS_TITLE' | translate }}
                    </h1>
                </div>
            </div>
            <div class="col-lg-7 vertical-align-content">
                <div class="left">
                    <p i18n="@@feature4Title" class="text grey text-16">{{'SOLUTION2.EXTRA_DETAILS_SUBTITLE' | translate
                        }}
                    </p>

                    <p class="text grey text-16">
                        <b>Automatizarea colectei cheltuielilor lunare:</b> Simplifică procesul de colectare a
                        cheltuielilor recurente,
                        folosind Propero pentru a elimina necesitatea colectării manuale. Configurează cheltuielile
                        lunare automat și
                        lasă sistemul să gestioneze toate plățile cu minimă intervenție.
                    </p>

                    <p class="text grey text-16">
                        <b>Notificări automate de plată:</b> Propero trimite notificări automate către chiriași,
                        reamintindu-le de
                        termenele de plată și sumele datorate, evitând astfel întârzierile și lipsa încasărilor lunare.
                    </p>

                    <p class="text grey text-16">
                        <b>Reconcilierea automată a cheltuielilor:</b> Asociază automat plățile efectuate la
                        cheltuielile specifice,
                        oferind o imagine clară și corectă asupra bugetului. Reconcilierea automată reduce riscul de
                        erori și permite
                        o vizualizare completă a tuturor cheltuielilor.
                    </p>

                    <p class="text grey text-16">
                        <b>Funcționalitate de Auto-Pay:</b> Propero permite activarea plății automate pentru cheltuieli
                        recurente,
                        eliminând necesitatea aprobărilor manuale. Odată setată, funcția Auto-Pay asigură efectuarea
                        plăților la timp
                        și fără intervenție, simplificând procesul de gestiune.
                    </p>
                </div>
            </div>
            <div class="col-5 features-image">
                <img src="assets/images/marketing/Bottom.svg" alt="Hero">
            </div>
        </div>
        <div class="section-footer mt-40">
            <ul class="slider-btn rounded-buttons center">
                <li><a i18n="@@buttonCreateAccountText" class="main-btn rounded-one"
                        href="https://admin.propero.io/register"
                        target="_blank">{{'SOLUTION1.CREATE_ACCOUNT' | translate }}</a></li>
            </ul>
        </div>
    </div>
</section>

<section class="about-area">
    <div class="container">
        <div class="row">
            <div class="faq-content">
                <div class="about-title">
                    <h6 i18n="@@faqTitle" class="sub-title">{{'SOLUTION2.QA_TITLE' | translate }}</h6>
                    <h4 i18n="@@faqSubtitleText" class="title">{{'SOLUTION2.QA_TEXT' | translate }}</h4>
                </div> <!-- faq title -->
                <div class="about-accordion">
                    <div class="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <a i18n="@@question1Title" href="#" data-toggle="collapse" data-target="#collapseOne"
                                    aria-expanded="true" aria-controls="collapseOne">
                                    {{'SOLUTION2.QUESTION_1' | translate}}
                                </a>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@question1Answer" class="text">
                                        {{'SOLUTION2.QUESTION_1_ANSWER' | translate}}
                                    </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <a i18n="@@question2Title" href="#" class="collapsed" data-toggle="collapse"
                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {{'SOLUTION2.QUESTION_2' | translate }}
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@question2Answer" class="text">
                                        {{'SOLUTION2.QUESTION_2_ANSWER' | translate }}
                                    </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <a i18n="@@question3Title" href="#" class="collapsed" data-toggle="collapse"
                                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    {{'SOLUTION2.QUESTION_3' | translate }}
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@question3Answer" class="text">
                                        {{'SOLUTION2.QUESTION_3_ANSWER' | translate }}
                                    </p>
                                </div>
                            </div>
                        </div> <!-- card -->
                        <!-- <div class="card">
                            <div class="card-header" id="headingFore">
                                <a href="#" class="collapsed" data-toggle="collapse" data-target="#collapseFore"
                                    aria-expanded="false" aria-controls="collapseFore">Can Tenants Set up Automatic
                                    Payments?
                                </a>
                            </div>
                            <div id="collapseFore" class="collapse" aria-labelledby="headingFore"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p class="text">Yes, once they’ve created their account they can turn on auto-pay
                                        and set the payment to be made the same time each month.
                                    </p>
                                </div>
                            </div>
                        </div>  -->
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <a i18n="@@question4Title" href="#" class="collapsed" data-toggle="collapse"
                                    data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    {{'SOLUTION2.QUESTION_4' | translate }}
                                </a>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <p i18n="@@question4Answer" class="text">
                                        {{'SOLUTION2.QUESTION_4_ANSWER' | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>