<section id="home" class="slider_area">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="slider-content">&nbsp;</div>
        </div>
    </div>
</section>

<section class="contact-area white-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-10">
                    <h3 class="title">{{'CALCULATOR3.TITLE' | translate }}</h3>
                    <p class="text">{{'CALCULATOR3.TEXT' | translate }}</p>
                </div>
            </div>
        </div>

        <form [formGroup]="formGroup" (ngSubmit)="calculate()">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="contact-wrapper form-style-two">
                        <div class="col-md-12">
                            <mat-form-field style="width: 100%">
                                <mat-label>{{'CALCULATOR3.MONTHLY_RENT' | translate }}</mat-label>
                                <input type="text" formControlName="monthlyRate" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field style="width: 100%">
                                <mat-label>{{'CALCULATOR3.MOVE_IN_DATE' | translate }}</mat-label>
                                <input matInput formControlName="moveInDate" [matDatepicker]="picker_moveInDate">
                                <mat-datepicker-toggle matSuffix [for]="picker_moveInDate"></mat-datepicker-toggle>
                                <mat-datepicker #picker_moveInDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <div class="form-input light-rounded-buttons mt-5 text-center">
                                <button [disabled]="!formGroup.valid"
                                    class="main-btn light-rounded-two">{{'CALCULATOR3.COMPUTE' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="result">
            <div class="single-contact-box mt-50 text-center">
                <div class="box-icon">
                    <i class="lni lni-calculator"></i>
                    <img src="assets/images/contact/F-Shape.svg" alt="" class="shape">
                </div>
                <div class="box-content">
                    <h4 class="box-title">{{ rentPerDay | number : '1.2-2' }} €</h4>
                    <p class="text" style="font-size: 12px;">{{'CALCULATOR3.DAILY_RENT' | translate
                        }}</p>
                    <p style="text-align: center;"><span style="font-size: 13px;">{{'CALCULATOR3.DAILY' |
                            translate }}</span></p>
                </div>
            </div>
            <div class="single-contact-box mt-50 text-center">
                <div class="box-icon">
                    <i class="lni lni-grow"></i>
                    <img src="assets/images/contact/F-Shape.svg" alt="" class="shape">
                </div>
                <div class="box-content">
                    <h4 class="box-title">{{ prorateDays | number : '1.0-0' }} </h4>
                    <p class="text" style="font-size: 12px;">{{'CALCULATOR3.DAYS' | translate }}Zile</p>
                    <p style="text-align: center;"><span style="font-size: 13px;"
                            i18n="@@dailyMessage">{{'CALCULATOR3.DAILY_MESSAGE' | translate }}</span></p>
                </div>
            </div>
            <div class="single-contact-box mt-50 text-center">
                <div class="box-icon">
                    <i class="lni lni-euro"></i>
                    <img src="assets/images/contact/F-Shape.svg" alt="" class="shape">
                </div>
                <div class="box-content">
                    <h4 class="box-title">{{ prorateRent | number : '1.2-2'}} €</h4>
                    <p class="text" style="font-size: 12px;">{{'CALCULATOR3.PARTIAL_RENT' |
                        translate }}</p>
                    <p style="text-align: center;"><span style="font-size: 13px;">{{'CALCULATOR3.TOTAL' | translate
                            }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>