<nav class="navigation max-width-1 m-auto">
    <div class="nav-left">
        <a href="/">
            <span><img src="assets/img/blog-img/logo.png" width="94px" alt=""></span>
        </a>
        <ul>
            <li><a href="/blog-list">Acasă</a></li>
            <li><a href="/main">Propero</a></li>
            <!-- <li><a href="/contact.html">Contact</a></li> -->
        </ul>
    </div>

    <!-- <div class="nav-right">
        <form action="/search.html" method="get">
            <input class="form-input" type="text" name="query" placeholder="Article Search">
            <button class="btn">Search</button>
        </form>
    </div> -->
</nav>

<div class="max-width-1 m-auto">
    <hr>
</div>

<div class="max-width-1 m-auto">
    <hr>
</div>

<router-outlet></router-outlet>

<div class="footer">
    <p>Copyright &copy; itwiththebest.com </p>
    <a href="https://itwiththebest.com">ItWithTheBest</a>
</div>
