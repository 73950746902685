<!--====== SLIDER PART START ======-->
<!-- https://ayroui.com/ -->

<section id="home" class="slider_area">
    <div id="carouselThree" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselThree" data-slide-to="0" class="active"></li>


        </ol>
        <div class="carousel-inner">

            <div class="carousel-item active">
                <div class="container">
                    <div class="row flex-container">
                        <div class="col-lg-7">
                            <div class="slider-content">
                                <h1 class="title">{{ 'SOLUTION3.TITLE' | translate }}</h1>
                                <p class="text">{{ 'SOLUTION3.TEXT' | translate }}</p>
                                <ul class="slider-btn rounded-buttons">
                                    <li><a class="main-btn rounded-one"
                                            href="https://admin.propero.io/" target="_blank">Propero
                                            Assistant</a>
                                        <br />
                                        <span i18n="@@mainSubtitleAdditionalText" class="small-text">{{
                                            'HOME.MAIN_SUBTITLE_ADDITIONAL_TEXT' | translate }}
                                        </span>
                                        <br />
                                    </li>
                                    <li>
                                        <a i18n="@@mainPersonalizedOffer" class="slave-btn rounded-one"
                                            routerLink="/solution-personalised">{{
                                            'HOME.MAIN_PERSONALIZED_OFFER' | translate
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="slider-image">
                                <img src="assets/images/slider/2.png" alt="Hero">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <a class="carousel-control-prev" href="#carouselThree" role="button" data-slide="prev">
            <i class="lni lni-arrow-left"></i>
        </a>
        <a class="carousel-control-next" href="#carouselThree" role="button" data-slide="next">
            <i class="lni lni-arrow-right"></i>
        </a>
    </div>
</section>

<section class="features-area white-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-10">
                    <h3 class="title">Rapoarte profesionale</h3>
                    <p class="text">Obțineți informații detaliate despre performanța portofoliului și identificați
                        acțiunile necesare pentru a crește rentabilitatea, cu o analiză clară și centralizată a
                        veniturilor și cheltuielilor fiecărei proprietăți. Gestionați impozitele anuale, atât locale,
                        cât și ANAF, într-un mod simplificat și eficient, totul fiind calculat automat și integrat
                        într-o singură platformă pentru întregul portofoliu de imobile.</p>
                </div>
            </div>
        </div>

        <div class="row compare-area-table justify-content-center">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div>
                                <img src="assets/images/marketing/Image-12-3.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@feature1Title" class="features-title center ">
                                {{'SOLUTION3.FEATURE1_TITLE' | translate }}
                            </h4>
                        </div>
                        <div class="features-content">
                            <p i18n="@@feature1Text" class="tooltip-text-content">
                                {{'SOLUTION3.FEATURE1_TEXT' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div>
                                <img src="assets/images/marketing/Image-12-1.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@feature2Title" class="features-title center">
                                {{'SOLUTION3.FEATURE2_TITLE' | translate }}
                            </h4>
                        </div>
                        <div class="features-content">
                            <p i18n="@@feature2Text" class="tooltip-text-content">
                                {{'SOLUTION3.FEATURE2_TEXT' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div>
                                <img src="assets/images/marketing/Image-12-2.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@feature3Title" class="features-title center">
                                {{'SOLUTION3.FEATURE3_TITLE' | translate }}
                            </h4>
                        </div>
                        <div class="features-content">
                            <p i18n="@@feature3Text" class="tooltip-text-content">
                                {{'SOLUTION3.FEATURE3_TEXT' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-footer center mt-40">
            <ul class="slider-btn rounded-buttons">
                <li><a i18n="@@buttonCreateAccountText" class="main-btn rounded-one"
                        href="https://admin.propero.io/register"
                        target="_blank">{{'SOLUTION3.CREATE_ACCOUNT' | translate }}</a></li>
            </ul>
        </div>
    </div>
</section>

<section class="gray-area">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="left">
                    <h1 i18n="@@featureAutomaticAccountancyTitle" class="features-title left">
                        {{'SOLUTION3.EXTRA_DETAILS_TITLE' | translate }}
                    </h1>
                </div>
            </div>
            <div class="col-7 vertical-align-content">
                <div class="left">
                    <p i18n="@@featureAutomaticAccountancyText" class="text grey text-16">{{'SOLUTION1.ITEM4_TEXT' |
                        translate }}</p>

                    <p class="text grey text-16">
                        <b>Eliminarea erorilor de interacțiune umană:</b> Cu Propero, reduceți riscul de erori datorate
                        introducerii manuale și asigurați o contabilitate exactă și precisă. Automatizarea proceselor
                        minimizează influența factorului uman și îmbunătățește rapiditatea și eficiența.
                    </p>

                    <p class="text grey text-16">
                        <b>Raport profitabilitate vs. pierderi:</b> Analizați rapid raportul dintre profitabilitate și
                        pierderi pentru a înțelege performanța generală a portofoliului și pentru a lua decizii
                        strategice informate.
                    </p>

                    <p class="text grey text-16">
                        <b>Raport întârzieri la plata chiriei:</b> Monitorizați situația întârzierilor la plata chiriei
                        și obțineți o imagine clară asupra plăților restante, pentru a menține încasările la timp.
                    </p>

                    <p class="text grey text-16">
                        <b>Raport notificări:</b> Vizualizați toate notificările transmise, de la reamintiri de plată
                        până la mesaje de informare, și urmăriți eficiența comunicării cu chiriașii.
                    </p>

                    <p class="text grey text-16">
                        <b>Raport întârzieri la plata cheltuielilor de utilități:</b> Urmăriți întârzierile la plata
                        cheltuielilor de utilități și asigurați o gestionare corectă a plăților pentru a evita
                        penalizările și întreruperile.
                    </p>

                    <p class="text grey text-16">
                        <b>Raport plăți efectuate:</b> Verificați cu ușurință toate plățile realizate pentru a avea o
                        evidență clară a cheltuielilor și pentru a vă gestiona bugetul eficient.
                    </p>

                    <p class="text grey text-16">
                        <b>Raport plăți lunare efectuate/neefectuate:</b> Urmăriți rapid plățile lunare, atât cele
                        realizate, cât și cele restante, și asigurați un flux de numerar stabil pentru întreaga
                        gestiune.
                    </p>
                </div>

            </div>

            <div class="col-lg-5 features-image right">
                <img src="assets/images/marketing/Report-Two-UK.svg" alt="Hero">
            </div> <!-- slider-imgae box -->

        </div>
        <div class="section-footer mt-40">
            <ul class="slider-btn rounded-buttons center">
                <li><a i18n="@@featureCreateAccountButtonText" class="main-btn rounded-one"
                        href="https://admin.propero.io/register"
                        target="_blank">{{'SOLUTION3.CREATE_ACCOUNT' | translate }}</a></li>
            </ul>
        </div>
    </div>
</section>