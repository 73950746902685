<!--====== NAVBAR TWO PART START ======-->
<!--
    REALIT
    Renex
    HOUSIO
    Rentic
    tenary
    realfavorite
-->
<section class="navbar-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg">
                    <a class="navbar-brand" href="./">
                        <!-- <img src="assets/images/Logo-0.svg" alt="Logo"> -->
                        <img src="assets/logo-white.png" alt="Logo" style="max-width: 96px; max-height: 96px;">
                    </a>

                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTwo"
                        aria-controls="navbarTwo" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                        <span class="toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse sub-menu-bar" id="navbarTwo">
                        <ul class="navbar-nav m-auto">
                            <li class="nav-item active">
                                <a class="page-scroll" [routerLink]="['../main']" fragment="home"> {{
                                    'LAYOUT.MENU_HOME' | translate }} </a>
                            </li>
                            <li class="nav-item"><a class="page-scroll" [routerLink]="['../main']" fragment="services">
                                    {{
                                    'LAYOUT.MENU_SOLUTIONS' | translate }} </a></li>
                            <!-- <li class="nav-item"><a class="page-scroll" href="#portfolio">Portfolio</a></li> -->
                            <li class="nav-item"><a class="page-scroll" [routerLink]="['../main']" fragment="pricing">
                                    {{
                                    'LAYOUT.MENU_FINANCIAL_PLAN' | translate }} </a></li>
                            <li class="nav-item"><a class="page-scroll" [routerLink]="['../main']"
                                    fragment="resources">{{
                                    'LAYOUT.MENU_RESOURCES' | translate }}</a></li>
                            <li class="nav-item"><a class="page-scroll" [routerLink]="['../main']" fragment="blog">{{
                                    'LAYOUT.MENU_BLOG' | translate }}</a></li>
                            <li class="nav-item"><a class="page-scroll" [routerLink]="['../main']" fragment="about">{{
                                    'LAYOUT.MENU_ABOUT' | translate }}</a></li>
                            <!-- <li class="nav-item"><a class="page-scroll" href="#team">Team</a></li> -->
                            <li class="nav-item"><a class="page-scroll" [routerLink]="['../main']" fragment="contact">{{
                                    'LAYOUT.MENU_CONTACT' | translate }}</a></li>
                        </ul>
                    </div>

                    <div class="navbar-btn d-none d-sm-inline-block">
                        <ul>
                            <li><a class="solid" href="https://admin.propero.io/"
                                    target="_blank">Propero assistant</a>
                            </li>
                        </ul>
                    </div>

                    <div class="navbar-btn d-none d-sm-inline-block">
                        <ul>
                            <li>
                                <div class="single-checkout-form form-input">
                                    <div class="input-items default">
                                        <select #langSelect (change)="onChangeLanguage(langSelect.value)">
                                            <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                                                [selected]="lang === translate.currentLang">{{ getLanguage(lang) }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </nav> <!-- navbar -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</section>

<!--====== NAVBAR TWO PART ENDS ======-->


<router-outlet></router-outlet>


<!--====== BACK TOP TOP PART START ======-->

<a routerLink="./" class="back-to-top"><i class="lni lni-chevron-up"></i></a>

<!--====== BACK TOP TOP PART ENDS ======-->


<!--====== NAVBAR TWO PART ENDS ======-->


<!--====== FOOTER PART START ======-->

<section class="footer-area footer-dark">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="footer-logo text-center">
                    <img src="assets/logo-white.png" alt="Logo" style="max-width: 96px; max-height: 96px;">
                </div> <!-- footer logo -->
                <ul class="social text-center mt-60">
                    <!-- <li><a href="https://facebook.com/uideckHQ"><i class="lni lni-facebook-filled"></i></a></li>
                    <li><a href="https://twitter.com/uideckHQ"><i class="lni lni-twitter-original"></i></a></li>
                    <li><a href="https://instagram.com/uideckHQ"><i class="lni lni-instagram-original"></i></a></li>
                    <li><a href="#"><i class="lni lni-linkedin-original"></i></a></li> -->
                    <li><a routerLink="./"><i class="lni lni-facebook-filled"></i></a></li>
                    <li><a routerLink="./"><i class="lni lni-twitter-original"></i></a></li>
                    <li><a routerLink="./"><i class="lni lni-instagram-original"></i></a></li>
                    <li><a routerLink="./"><i class="lni lni-linkedin-original"></i></a></li>
                </ul> <!-- social -->
                <div class="footer-support text-center">
                    <span class="number">(+4) 0743 366 266</span>
                    <span class="mail">contact@propero.com</span>
                </div>
                <!-- <div class="copyright text-center mt-35">
                    <p class="text">Designed by <a href="https://uideck.com" rel="nofollow">UIdeck</a> and
                        Built-with <a rel="nofollow" href="https://ayroui.com">Ayro UI</a> </p>
                </div> -->
                <!--  copyright -->
            </div>
        </div> <!-- row -->
    </div> <!-- container -->
</section>

<!--====== FOOTER PART ENDS ======-->