<!--====== SLIDER PART START ======-->
<!-- https://ayroui.com/ -->

<section id="home" class="slider_area">
    <div id="carouselThree" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselThree" data-slide-to="0" class="active"></li>


        </ol>
        <div class="carousel-inner">

            <div class="carousel-item active">
                <div class="container">
                    <div class="row flex-container">
                        <div class="col-lg-7">
                            <div class="slider-content">
                                <h1 i18n="@@sectionPersSolutionTitle" class="title">{{'PERSONALIZED_SOLUTION.TITLE' |
                                    translate }}</h1>
                                <p i18n="@@sectionPersSolutionText" class="text white">{{'PERSONALIZED_SOLUTION.TEXT' |
                                    translate }}
                                </p>
                                <ul class="slider-btn rounded-buttons">
                                    <li><a class="main-btn rounded-one"
                                            href="https://admin.propero.io/" target="_blank">Propero
                                            Assistant</a>
                                        <br />
                                        <span i18n="@@mainSubtitleAdditionalText" class="small-text">{{
                                            'HOME.MAIN_SUBTITLE_ADDITIONAL_TEXT' | translate }}
                                        </span>
                                        <br />
                                    </li>
                                    <li>
                                        <a i18n="@@mainPersonalizedOffer" class="slave-btn rounded-one"
                                            routerLink="/solution-personalised">{{
                                            'HOME.MAIN_PERSONALIZED_OFFER' | translate
                                            }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="slider-image">
                                <img src="assets/images/slider/2.png" alt="Hero">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <a class="carousel-control-prev" href="#carouselThree" role="button" data-slide="prev">
            <i class="lni lni-arrow-left"></i>
        </a>
        <a class="carousel-control-next" href="#carouselThree" role="button" data-slide="next">
            <i class="lni lni-arrow-right"></i>
        </a>
    </div>
</section>

<section class="features-area white-area">
    <div class="container">
        <div class="row compare-area-table justify-content-center">
            <div class="row justify-content-center">

                <div class="col-lg-5 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div class="icon center">
                                <img src="assets/images/marketing/S-Personalized-Cloud.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@featurePublicCloudTitle" class="features-title center mt-4">
                               {{'PERSONALIZED_SOLUTION.SOLUTION1_TITLE' | translate }}</h4>
                        </div>
                        <div class="features-content">
                            <p class="tooltip-text-content">
                                <span> {{'PERSONALIZED_SOLUTION.SOLUTION1_SUBTITLE' | translate }}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div class="icon center">
                                <img src="assets/images/marketing/S-Personalized-WhiteLabel-Logo.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@featureWhitelabelTitle" class="features-title center mt-4">
                                {{'PERSONALIZED_SOLUTION.SOLUTION2_TITLE' | translate }}</h4>
                        </div>
                        <div class="features-content">
                            <p class="tooltip-text-content">
                                {{'PERSONALIZED_SOLUTION.SOLUTION2_SUBTITLE' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div class="icon center">
                                <img src="assets/images/marketing/S-Personalized-Db-Cloud.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@featurePrivateCloudTitle" class="features-title center mt-4">
                                {{'PERSONALIZED_SOLUTION.SOLUTION3_TITLE' | translate }}</h4>
                        </div>
                        <div class="features-content">
                            <p class="tooltip-text-content">
                                {{'PERSONALIZED_SOLUTION.SOLUTION3_SUBTITLE' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-7 col-sm-9">
                    <div class="single-features mt-40">
                        <div class="justify-content-between">
                            <div class="icon center">
                                <img src="assets/images/marketing/S-Personalized-Db.svg" alt="Shape">
                            </div>
                            <h4 i18n="@@featureDatacenterTitle" class="features-title center mt-4">
                                {{'PERSONALIZED_SOLUTION.SOLUTION4_TITLE' | translate }}</h4>
                        </div>
                        <div class="features-content">
                            <p class="tooltip-text-content">
                                {{'PERSONALIZED_SOLUTION.SOLUTION4_SUBTITLE' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="section-footer center mt-40">
            <ul class="slider-btn rounded-buttons">
                <li><a i18n="@@featureButtonDetailsText" class="main-btn rounded-one"
                        href="/solution-personalised/#contact"> {{'PERSONALIZED_SOLUTION.WANT_DETAILS' | translate
                        }}</a></li>
            </ul>
        </div>
    </div>
</section>

<section id="contact" class="gray-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center">
                    <h3 i18n="@@sectionContactTitle" class="title">{{'PERSONALIZED_SOLUTION.CONTACT' | translate
                        }}</h3>
                    <p i18n="@@sectionContactText" class="text">{{'PERSONALIZED_SOLUTION.CONTACT_TEXT' | translate }}
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="contact-wrapper form-style-two pt-115">
                    <h4 class="contact-title pb-10">
                        <i class="lni lni-envelope"></i><span
                            i18n="@@sectionContactText">{{'PERSONALIZED_SOLUTION.CONTACT2' | translate }}</span>
                    </h4>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-input mt-25">
                                <label i18n="@@sectionContactInputName">{{'PERSONALIZED_SOLUTION.NAME' | translate
                                    }}</label>
                                <div class="input-items default">
                                    <input matInput required name="name" type="text" placeholder="Nume"
                                        [(ngModel)]="contactItem.subject" ngDefaultControl>
                                    <i class="lni lni-user"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-input mt-25">
                                <label i18n="@@sectionContactInputEmail">{{'PERSONALIZED_SOLUTION.EMAIL' | translate
                                    }}</label>
                                <div class="input-items default">
                                    <input matInput type="email" name="email" placeholder="Email"
                                        [(ngModel)]="contactItem.email" ngDefaultControl>
                                    <i class="lni lni-envelope"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-input mt-25">
                                <label i18n="@@sectionContactInputMessage">{{'PERSONALIZED_SOLUTION.MESSAGE' | translate
                                    }}</label>
                                <div class="input-items default">
                                    <textarea matInput required name="massage" placeholder="Mesaj"
                                        [(ngModel)]="contactItem.content" ngDefaultControl></textarea>
                                    <i class="lni lni-pencil-alt"></i>
                                </div>
                            </div>
                        </div>
                        <p class="form-message"></p>
                        <div class="col-md-12 right">
                            <div class="form-input light-rounded-buttons mt-30">
                                <button i18n="@@featureButtonRequestOfferText" class="main-btn light-rounded-two"
                                    (click)="send()">{{'PERSONALIZED_SOLUTION.ASK_OFFER' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>