<section id="home" class="slider_area">
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div class="slider-content">&nbsp;</div>
        </div>
    </div>
</section>

<section class="contact-area white-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="section-title text-center pb-10">
                    <h3 class="title">{{'CALCULATOR2.TITLE' | translate }}</h3>
                    <p class="text">{{'CALCULATOR2.TEXT' | translate }}</p>
                </div>
            </div>
        </div>

        <form [formGroup]="formGroup" (ngSubmit)="calculate()">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="contact-wrapper form-style-two">
                        <div class="col-md-12">
                            <mat-form-field style="width: 100%">
                                <mat-label>{{'CALCULATOR2.LOAN_AMOUNT' | translate }}</mat-label>
                                <input type="text" formControlName="loanAmount" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field style="width: 100%">
                                <mat-label>{{'CALCULATOR2.LOAN_PERIOD' | translate }}</mat-label>
                                <input type="text" formControlName="loanPeriod" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field style="width: 100%">
                                <mat-label>{{'CALCULATOR2.INTEREST_RATE' | translate }}</mat-label>
                                <input type="text" formControlName="interestRate" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field style="width: 100%">
                                <mat-label>{{'CALCULATOR2.ANNUAL_TAXES' | translate }}</mat-label>
                                <input type="text" formControlName="annualTaxes" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field style="width: 100%">
                                <mat-label>{{'CALCULATOR2.ANNUAL_INSURANCE' | translate }}</mat-label>
                                <input type="text" formControlName="annualInsurance" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <div class="form-input light-rounded-buttons mt-5 text-center">
                                <button [disabled]="!formGroup.valid"
                                    class="main-btn light-rounded-two">{{'CALCULATOR2.COMPUTE' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="result">
            <div class="single-contact-box mt-50 text-center">
                <div class="box-icon">
                    <i class="lni lni-calculator"></i>
                    <img src="assets/images/contact/F-Shape.svg" alt="" class="shape">
                </div>
                <div class="box-content">
                    <h4 class="box-title">{{ totalPayment | number : '1.2-2' }} €</h4>
                    <p class="text" style="font-size: 12px;">{{'CALCULATOR2.TOTAL_PAYMENT' | translate }}</p>
                    <p style="text-align: center;"><span style="font-size: 13px;">{{'CALCULATOR2.PERIOD' | translate
                            }}</span></p>
                </div>
            </div>
            <div class="single-contact-box mt-50 text-center">
                <div class="box-icon">
                    <i class="lni lni-grow"></i>
                    <img src="assets/images/contact/F-Shape.svg" alt="" class="shape">
                </div>
                <div class="box-content">
                    <h4 class="box-title">{{ amountPaid | number : '1.2-2' }} €</h4>
                    <p class="text" style="font-size: 12px;">{{'CALCULATOR2.AMOUNT_PAID' | translate }}</p>
                    <p style="text-align: center;"><span style="font-size: 13px;">{{'CALCULATOR2.MONTHLY' | translate
                            }}</span></p>
                </div>
            </div>
            <div class="single-contact-box mt-50 text-center">
                <div class="box-icon">
                    <i class="lni lni-euro"></i>
                    <img src="assets/images/contact/F-Shape.svg" alt="" class="shape">
                </div>
                <div class="box-content">
                    <h4 class="box-title">{{ taxes | number : '1.2-2'}} €</h4>
                    <p class="text" style="font-size: 12px;">{{'CALCULATOR2.TAXES' | translate }}</p>
                    <p style="text-align: center;"><span style="font-size: 13px;">{{'CALCULATOR2.MONTHLY' | translate
                            }}</span></p>
                </div>
            </div>
            <div class="single-contact-box mt-50 text-center">
                <div class="box-icon">
                    <i class="lni lni-alarm-clock"></i>
                    <img src="assets/images/contact/F-Shape.svg" alt="" class="shape">
                </div>
                <div class="box-content">
                    <h4 class="box-title">{{ insurance | number : '1.2-2'}} €</h4>
                    <p class="text" style="font-size: 12px;">{{'CALCULATOR2.INSURANCE' | translate }}</p>
                    <p style="text-align: center;"><span style="font-size: 13px;">{{'CALCULATOR2.MONTHLY' | translate
                            }}</span></p>
                </div>
            </div>
        </div>
    </div>
</section>