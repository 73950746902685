<ng-container *ngIf="comments">
    <div class="comments" *ngFor="let comment of comments">
        comments
        <app-card [comment]="comment" (update)="updateComment($event)"
            (delete)="onDeleteComment($event)" (vote)="updateScore($event)">
        </app-card>
        <!-- <app-card [comment]="comment" [currentUser]="currentUser" (update)="updateComment($event)"
            (delete)="onDeleteComment($event)" (vote)="updateScore($event)">
        </app-card> -->

        <!-- <div class="replies" *ngIf="comment.replies.length > 0">
            <div *ngFor="let reply of comment.replies">
                <app-card [comment]="reply" [currentUser]="currentUser" (update)="updateComment($event)"
                    (delete)="onDeleteComment($event)" (vote)="updateScore($event)">
                </app-card>
            </div>
        </div> -->
    </div>

    <!-- <app-form [currentUser]="currentUser" (add)="addComment($event)"></app-form> -->
</ng-container>


<modal id="modal-1">
    <h3 class="fw-500 modal-title">Delete comment</h3>
    <p class="modal-context">Are you sure you want to delete this comment? This will remove the comment and can’t be
        undone.</p>
    <div class="update__btns d-flex">
        <button class="btn bg-grayish-blue btn-secondary-fill" (click)="onCancel()">NO, CANCEL</button>
        <button class="btn bg-soft-red btn-delete-fill" (click)="deleteComment()">YES, DELETE</button>
    </div>
</modal>
